import React from 'react'
import styled, { css } from 'styled-components'

import Theme from './shared/colors'
import Spacing from './shared/spacing'

import { H1, typestyle } from './shared/typography'

const Cover = ({scrollRef}) => {

    const handleOnClick = (event) => {
        scrollRef.current.scrollIntoView({ 
            behavior: "smooth", 
            block: "nearest"
         })
    }

    return (
        <Section>
            <H1 size={'cover'}>
                <LogoIcon 
                    src={'./logo-icon.svg'}
                />
            </H1>
            <Button size={'h5'} onClick={handleOnClick}>
                Who is Brand X?
                <Icon src={'./chevron-down.svg'} />
            </Button>
        </Section>
    )
}

const Section = styled.section`
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding-bottom: 4rem;
    height: 80vh;
    text-align: center;
    color: ${Theme.text.brand};
`

const LogoIcon = styled.img`
    margin: 0 auto;
    max-width: 100%;
    max-height: 40vh;
`

const Button = styled.button`
    margin: ${Spacing.xl} 0;
    ${(props) => {
        props.size = props.size ? props.size : 'body'
        return typestyle()
    }}
    background-color: transparent;
    border: none;
    color: ${Theme.text.brand};

    &:focus {
        outline: 0;
    }
`

const Icon = styled.img`
    position: absolute;
    bottom: 2rem;
    left: 50%;
    width: 4rem;
    transform: translateX(-2rem);
`

export default Cover